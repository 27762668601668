import "@fontsource-variable/inter"
import "@fontsource/syncopate/latin-700.css"
import "@total-typescript/ts-reset"

import { ThemeProvider } from "next-themes"
import type { AppProps } from "next/app"
import Head from "next/head"

import "../globals.css"

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <ThemeProvider attribute="class" enableSystem>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <Component {...pageProps} />
      <script
        src="https://a.usbrowserspeed.com/cs?pid=4067a734489a4605812955e4be8e1297f8fc9a5825bc27dfb1be138766ecccce"
        type="text/javascript"
      />
    </ThemeProvider>
  )
}

export default App
